import React from 'react';
import Loader from 'react-loader-spinner'

class Load extends React.Component {

    render() {
        return (
            <div className="loader">
                <Loader type="Rings" color="#00BFFF" height={80} width={80} />
            </div>
            
        )
    }
}

export default Load;