import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import Loader from './component/common/loader';



const LazyHeader = React.lazy(() => import('./component/common/header'))
const LazyFooter = React.lazy(() => import('./component/common/footer'))
const Lazy404 = React.lazy(() => import('./component/common/404'))
const LazyIndex = React.lazy(() => import('./component'))
const LazyProject = React.lazy(() => import('./component/project'))
// const LazyTopics = React.lazy(() => import('./Topics'))
// const LazySettings = React.lazy(() => import('./Settings'))


const rediect = () => {
  return <Redirect to="/" />
}

class App extends React.Component {
  render() {
    return (
      <React.Suspense fallback={<Loader />}>
        <Router>
          <div>
          <LazyHeader />
          <Switch>
            <Route exact path='/' component={LazyIndex} />
            <Route exact path='/project/:name' component={LazyProject} />
            <Route exact path='/project/' component={rediect} />
            <Route path="*" component={Lazy404} />
            {/* <Route path='/topics' component={LazyTopics} />
            <Route path='/settings' component={LazySettings} /> */}
          </Switch>
          <LazyFooter />
          </div>
        </Router>
      </React.Suspense>
    )
  }
}

export default App
